import {arrow} from '@tightrope/newtab';
const Util = require("./util.js");
const SettingsManager = require("./SettingsManager.js");
const AT = require("./Arcadetab.js");
const TOP_DOMAIN = Util.TOP_DOMAIN;

let scrolling = false;
let extGames = SettingsManager.retrieveGamelinks();
let parsed_glinks = [];
let recentlyPlayedGames = [];

recentlyPlayedGames = localStorage.getItem("recentGame") ? JSON.parse( localStorage.getItem("recentGame")) : [];


// Used for search autocomplete
var searchElement = 'search-text';
if(searchElement && searchElement !== '')  window._tr_ac_se = searchElement;

$(document).ready(async function() {
    var userGuid = Util.getSetting("guid");
    arrow("newtab");
    $("#search-text").focus();
    // Set Date on DOM
    let currentDate = Util.getCurrentDate();
    $("#current_date").html(currentDate);

    $(document).on("click", function(e) {
        if($('#lightbox').css('display') === 'block' && ($('.bubble_A').css('display') === 'block' || $('.bubble_B').css('display') === 'block')) {
            if($(e.target).attr('id') === 'lightbox') {
                $('#lightbox').fadeOut('fast');
                $('.bubble_A, .bubble_B').fadeOut('fast');
                if(AT.activeCookieCategory !== '' && AT.cookieCatPresent) {
                    $(AT.activeCookieCategory).fadeIn(200);
                    AT.cookieCatPresent = false;
                }
            }
        }
    });

    // Update DOM Time
    await checkFirstRun();

    // Add games links to Panel on DOM
        extGames.then((category) => {
            parsed_glinks = category;
            //updating topBar data with change of feed
            let rendered = SettingsManager.updateTopBar(parsed_glinks);
            $('#bar').html(rendered);
            parsed_glinks.forEach(function (eachFeed) {
                let innerRendered = SettingsManager.innerTopBar(eachFeed.data);
                $('#' + eachFeed.name + 'List').html(innerRendered);
            });
        });


    $(".starBtn").click(function() {
        $(".linksBox").toggleClass("expand");
    });

    //update Recently Played Games
    let rendered = (recentlyPlayedGames.length >0) ? SettingsManager.getRecentPlayedHTML(recentlyPlayedGames) : `<li style="color: white;"> You haven't played any games yet.</li>`;
    $('.recentPlayedID').html(rendered);


    // Arcadetab handlers
    $(document).on("click", ".gameEntry", async function(e){
        e.preventDefault();
        let gameID = $(this)[0].dataset.identifier;
         Util.setSetting('saved_game', gameID);
        AT.displaySelectedGame(gameID);
    });

    $(document).on('mouseenter', '#gameList li', function(e) {
        let bannerLink = $(this).find('a.game-banner-link');

        if(bannerLink.length > 0 && !$(bannerLink[0]).hasClass('active')){
            $(bannerLink[0]).addClass('active');
        }
    });
    $(document).on('mouseleave', '#gameList li', function(e) {
        let bannerLink = $(this).find('a.game-banner-link');

        if(bannerLink.length > 0 && $(bannerLink[0]).hasClass('active')){
            $(bannerLink[0]).removeClass('active');
        }
    });

    $('ul.setCat').on("click",' li', function(e){
        let newCat = $(e.target).attr("cat");
        $('ul.setCat li.active').removeClass('active');

        $('ul.setCat li[cat="'+newCat+'"]').addClass('active');
        if (newCat) localStorage.setItem("cat", newCat);
    });

    $('.moreGenre').bind("change", async function() {
        var genre_names=await SettingsManager.giveGenreName($(this).val());
        trackGenreClick('more' + genre_names);
        await  AT.init(false, $(this).val());
    });


    $('ul.nav__menu').on("click",' li', async function(e){
        let newCat = (this == e.target) ? $(e.target).attr("cat") : $(e.target.parentElement).attr("cat") ;
        if(newCat !== 'all') {
            $('ul.nav__menu li.active').removeClass('active');
            $('ul.nav__menu li[cat="'+newCat+'"]').addClass('active');
            await AT.init(false,newCat,true);
            SettingsManager.giveGenreName(newCat).then((res) => {
            });
        } else {
            $('ul.nav__menu li.active').removeClass('active');
            $('ul.nav__menu li[cat="all"]').addClass('active');
            await AT.init(false,'all',true);
        }
    });


    $('.moreGenre').bind("change",function(){
        AT.init(false,$(this).val(), true);
        SettingsManager.giveGenreName($(this).val()).then((res) => {
        });
    });

    $('body').click(function (event)
    {
        if(!$(event.target).closest('#gameInfo').length && !$(event.target).is('#gameInfo')) {
            $("#gameInfo").hide();
        }
    });

    var firstRunTimer;
    $(document).on("click", ".closeBtn", function(e) {
        e.preventDefault();
        $("#firstRunModal").fadeOut();
        $('.bubble_A').fadeIn();
        firstRunTimer = setTimeout(function() {
            $('.bubble_A').fadeOut('slow');
            $('#lightbox').fadeOut('slow');
            if(AT.activeCookieCategory !== '' && AT.cookieCatPresent) {
                $(AT.activeCookieCategory).fadeIn(200);
                AT.cookieCatPresent = false;
            }
        }, 4000);
    });
    $(document).on('click', '.closeBubble_A', function(e) {
        e.preventDefault();
        clearTimeout(firstRunTimer);
        $('.bubble_A').fadeOut();
        $('.bubble_B').fadeIn();
    });
    $(document).on('click', '.closeBubble_B', function(e) {
        e.preventDefault();
        $('.bubble_B').fadeOut();
        $('#lightbox').fadeOut();

        if(AT.activeCookieCategory !== '' && AT.cookieCatPresent) {
          $(AT.activeCookieCategory).fadeIn(200);
          AT.cookieCatPresent = false;
        }
    });
    $(document).on('click', '#recent_games', function(e) {
        $('.recentGamesTooltip').toggle();
    });

    //Hide - Show games content
    $(document).ready(function(){
        $(".vn-click").click(function(){
            localStorage.setItem("showGames", !$('.gamesContainer').is(':visible'));
            $(".gamesContainer").fadeToggle(100);
            $('ul.nav__menu').fadeToggle(100);
            $('.moreGenre').fadeToggle(100);
        });
    });
    $('.vn-click').on('click', function(){
        var that = $(this);
        if(that.text() == that.data("text-swap")){
            that.text(that.data("text-original"));
        } else {
            that.data("text-original", that.text());
            that.text(that.data("text-swap"));
        }
    });


    $(document).on("mouseenter", ".listCtrl_up", function() {
        scrolling = true;
        scrollContent("#gameList", "up");
    });

    $(document).on("mouseenter", ".listCtrl_down", function() {
        scrolling = true;
        scrollContent("#gameList", "down");
    });

    $(document).on("mouseleave", ".listCtrl", function() {
        scrolling = false;
    });
    $(document).on("click", ".list-collapse", async function() {
        let $gameListContainer = $("#gameListContainer");
        if($(gameListContainer).hasClass("active")){
            $gameListContainer.css({"right" : "-300px", "transition" : "0.2s all"});
            $gameListContainer.removeClass("active");
             Util.setSetting("hideGameList", true);
        }
        else {
            $gameListContainer.css({"right" : "0px", "transition" : "0.2s all"});
            $gameListContainer.addClass("active");
             Util.setSetting("hideGameList", false);
        }
    });
    $(document).on("click", ".gameManual", function() {
        $("#gameInfo").fadeIn("fast");
    });
    $(document).on("click", ".close-gameinfo", function() {
        $("#gameInfo").fadeOut("fast");
    });
    $(document).on("click", ".game-banner-link", function() {
        var current_url =  $(this).parent().find(".game-banner-link").attr('href');
        var current_title =  $(this).parent().find(".game-banner-link").attr('gameTitle');
        var current_obj = {"url": current_url,"title": current_title};

        if(recentlyPlayedGames.map((o) => o.title).indexOf(current_title) === -1) {
            recentlyPlayedGames.push(current_obj);
        }
        if(recentlyPlayedGames.length>10) {
            recentlyPlayedGames =  recentlyPlayedGames.slice(recentlyPlayedGames.length-10,recentlyPlayedGames.length);
        }
        localStorage.setItem("recentGame",JSON.stringify(recentlyPlayedGames) );
        $('.recentPlayedID').html(SettingsManager.getRecentPlayedHTML(recentlyPlayedGames));
        $(".recentGamesTooltip").toggle();
        setTimeout(function() {
            $(".recentGamesTooltip").fadeOut('fast');
        }, 3000);

    });

    $(document).on("click", ".recentGameList", function() {
    });
    $(document).on("click", ".firstRunGame", function() {
    });
    $(".shortCuts").click(function(e) {
    });

    // General Newtab handlers
    $("button#doSearch").click(function(e){
        e.preventDefault();
        $("#search-form").submit();
    });
    $("#search-form").submit(async function(e){
        e.preventDefault();
        window.location = "https://services." + TOP_DOMAIN + "/crx/search.php?action=nt&guid=" + userGuid + "&k=" + $("#search-text").val();
    });
    // Opens settings panel
    $(document).on("click", ".openSettings", function() {
        $("#cpanel").toggleClass("open");
    });

    $(document).on("change", "input[name=clocktype]", function() {
        let val = $(this).val();
        SettingsManager.applyClockSetting(val);
    });

    $('.bgColorInput, .clockColorInput').on('change', function() {
        if($(this).hasClass('bgColorInput')) {
            let newColor = $(this).attr('value');
            changeBGColor(newColor);
        }
        else if($(this).hasClass('clockColorInput')) {
            let newColor = $(this).attr('value');
            $('#main_clock').css('color', '#' + newColor);
        }
    });

    $(document).mouseup(function(e)
    {
        let settingsPanel = $('#cpanel');
        if (!settingsPanel.is(e.target) && settingsPanel.has(e.target).length === 0 && settingsPanel.hasClass('open')) {
            settingsPanel.toggleClass('open');
        }
    });


    let divNotification = $('div#notification');
    divNotification.hide();
});

function changeBGColor(newColor, selector = 'body') {
    $(selector).css('background', '#' + newColor);
}


// Check first run, if chrome use chrome api, otherwise use browser local storage
async function checkFirstRun(isFirstRun, isNotFirstRun) {
    if (new URL(window.location.href).searchParams.get("tutorial") === "true") {
        $(".firstrun").css("display", "block");
    }
    await AT.init(false)
}

function scrollContent(container, direction) {
  var amount = (direction === "up" ? "-=10px" : "+=10px");
   $(container).animate({
       scrollTop: amount
   }, 1, function() {
       if (scrolling) {
           // If we want to keep scrolling, call the scrollContent function again:
           scrollContent("#gameList", direction);
       }
   });
}


//onload fadin to prevet newtab from flashing
$(document).ready(function() {
    $('body').hide().fadeIn('fast');
    $('.firstrun').click(function() {
       $(this).hide();
    });
});


// Wall Clock
var hands = [];
hands.push(document.querySelector('#secondhand > *'));
hands.push(document.querySelector('#minutehand > *'));
hands.push(document.querySelector('#hourhand > *'));
