const Util = require("./util.js");

// Class for handling the settings panel
class SettingsManager {
  constructor() {
    this.settingsEndpoint = "settings.json";
    this.gamelinksEndpoint = "gamelinks.json";
    this.genresEndpoint = "https://arcade-services.myarcadetab.com/api/get.php";
    this.clocktype = "Digital";
    this.extSettings = {};
    this.extGames = {};
    this.genreCookie = '';
    this.gameCookie = '';
    this.savedSettings = {};
  }


  // Get settings from url via ajax
  retrieveSettings(settingsFile = this.settingsEndpoint) {
    let extSettings = Util.getPromise({
      url: settingsFile,
      dataType: 'json',
      mimeType: "application/json"
    });
    extSettings.then((res) => {
      this.extSettings = res;
    });
    return extSettings;
  }

giveGenreName(genreID) {
    return new Promise((resolve, reject) => {
        let  genreObj =JSON.parse(localStorage.getItem("retrievedGenres"));
        $.map(genreObj, function(obj) {
            if(obj.id === genreID) {
                resolve(obj.name);
                return obj.name;
            }
        });
    });
}
//  Get games genres and links from url via ajax
retrieveGenres() {
    let retGenres = JSON.parse(localStorage.getItem("retrievedGenres"));
    if (retGenres && !Util.checkIfUpdate(retGenres && retGenres[retGenres.length-1].lastCall, 1440)) {
        return new Promise((resolve, reject) => {
            resolve(retGenres);
            return retGenres;
        });
    } else {
        let genresData = {
            url: this.genresEndpoint,
            data: {
                action: "genres",
            },
        };
        return Util.getPromise(genresData).then((res) => {
            res.push({'lastCall' : Date.now()});
            var sorted_res = res.sort(function (a, b) {
                return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
            });
            localStorage.setItem('retrievedGenres', JSON.stringify(sorted_res));
            return new Promise((resolve, reject) => {
                resolve(sorted_res);
                return sorted_res;
            });
        });
    }
}

// Get games lists and links from url via ajax
retrieveGamelinks(gamesFile = this.gamelinksEndpoint) {
    let extGames = Util.getPromise({url: gamesFile});
    extGames.then((res) => this.extGames = typeof res === "string" ? JSON.parse(res) : res);
    return extGames;
}


//function to generate topBar options dynamically based on game Source selected
getGenreHTML(parsed_genre){
    const allHTML = `<li cat="all">All</li>`;
    let genreHTML = [];
    $('.moreGenre').empty().append('<option  style="display: none;" value="more">More</option>');
    parsed_genre.forEach(x => {
        if(parsed_genre.indexOf(x) < 6) {
        genreHTML.push(`<li cat="${x.id}">${x.name}</li>`);
        }
        else if(x.id && (parsed_genre.indexOf(x) >= 6))
        {
            $('.moreGenre').append(`<option value="${x.id}">${x.name}</option>`);
        }
});
    genreHTML =genreHTML.join('');
    return allHTML+genreHTML;
}
//function to generate genres in settings panel dynamically
getSettingsGenreHTML(parsed_genre){
    const allHTML = `<li cat="all">All</li>`;
    let genreHTML = [];
    parsed_genre.forEach(x => {if(x.id) { genreHTML.push(`<li cat="${x.id}">${x.name}</li>`); }
    });
    genreHTML =genreHTML.join('');
    return allHTML+genreHTML;
}

//function to generate topBar options dynamically based on game Source selected
getFirstRunGameHTML(parsed_game) {
    const gamesHTML = ({title, description, logo, snap, url, id, releaseYear, publisher, emulator_code}) =>
        ` <div class="gameTile"><a class='game-banner-link firstRunGame'  href='${url}' gameTitle= '${title}' target='_blank'>
              <img src="${logo}" class="gameLogo" data-identifier="${id}" data-emulatorcode="${emulator_code}"  alt=""
                onerror="this.style.display='none !important';"/>
           <p class="gameName">${title}</p>
            <a class='game-banner-link firstRunGame' href='${url}' gameTitle= '${title}' style="position: absolute;" target='_blank'>  <i class="fa fa-play-circle playButton " aria-hidden="true"></i> </a>
        </a></div>`;
    return parsed_game.map(gamesHTML).join('');
}



//function to show recently played games
getRecentPlayedHTML(parsed_recentGames){
    const recentHTML = ({url,title}) => `<li><a class="recentGameList" id="${title}id" this-title="${title}" href="${url}" target="_blank">${title}</a></li>`;
    return parsed_recentGames.map(recentHTML).join('');
}

//Update topBar on load with GoogleNews by default
showGenres() {
    return new Promise((resolve, reject) => {
        this.retrieveGenres().then((genresRes) => {
            let rendered = this.getGenreHTML(genresRes);
            let settingsRendered = this.getSettingsGenreHTML(genresRes);
            $('.nav__menu').html(rendered);
            $('.setCat').html(settingsRendered);
            resolve(true);
        });
    });
}

//function to generate topbar dropdown options dynamically
innerTopBar(val){
    const topBarHTML = ({value,link,img }) => `<li><a href="${link}" target="_blank">
     <img height="13" width="13" src='${img}' />${value}</a></li>`;
    return val.map(topBarHTML).join('');
}

//function to generate topBar tabs dynamically
updateTopBar(currentFeed){
    const outerTopBarHMTL = ({name, data}) => ` <li class="menu menu-hover">
     <a href="#" class="button">${name}</a>
     <ul id="${name}List" class="submenu">
       </ul></li>`;
    return currentFeed.map(outerTopBarHMTL).join('');
}

// Adds Settings read from settings.json to the DOM
  addSettingsToDOM(settingsArr = [], selector) {
    if(selector === undefined || settingsArr.length < 1) return;
    let targetLoc = $(selector);

    let groups = [];
    settingsArr.forEach((el) => {
      if(groups.indexOf(el.group) < 0) groups.push(el.group);
    });

    groups.forEach((el) => {
      let newGroupHTML = `<div class=${el}></div>`;
      $(targetLoc).append(newGroupHTML);
    });

    settingsArr.forEach((el) => {
      let html = this.getSettingHTML(el);
      $(`.${el.group}`).append(html);
      this.addSettingHandler(el);
    });
    this.addSettingButtonsHandlers();
  }
  // Save Button Handler
  async saveSettings(settingsContainer = ".ext-settings") {
    $(".loading").fadeIn("fast");
    setTimeout(function() {
      $(".loading").fadeOut("fast");
    }, 2000);
    let inputs = [].slice.call($(`${settingsContainer} input`),0);
    let s = await Util.getSetting("savedSettings");
    let existingSettings = typeof s === "string" ? JSON.parse(s) : s;
    let newSettings = Object.assign({}, existingSettings);
    inputs.forEach((el) => {
      if(el.type === "checkbox") {
        newSettings[el.dataset.identifier] = el.checked;
        // Add logic for saving checkbox state
      }
      else if(el.type === "text") {

        newSettings[el.dataset.identifier] = el.value;
        // Add logic for saving textbox value
      }

      else if(el.type === "radio") {
        let value = $(`input[name=${el.name}]:checked`).val();
        newSettings[el.dataset.identifier] = value;
      }

    });
    let bgColor = "#" + $('.bgColorInput').attr('value');
    let clockColor = "#" + $('.clockColorInput').attr('value');
    newSettings.bgColor = document.body.style.backgroundColor;
    newSettings.clockColor = clockColor;
    await Util.setSetting("savedSettings", JSON.stringify(newSettings));
    this.savedSettings = newSettings;

  }

  // Reset Defaults Handler
  async resetSettings(settingsContainer = ".ext-settings") {
    let inputs = [].slice.call($(`${settingsContainer} input`),0);

    inputs.forEach((input) => {
      this.extSettings.map((el) => {
        if(input.dataset.identifier === el.identifier) {
          switch(input.type) {
            case "checkbox":
              input.checked = el.defaultValue;
              break;
            case "text":
              input.setAttribute("value", el.defaultValue);
              break;
            case "radio":
              let radios = [].slice.call($(`input[name=${input.name}]`),0);
              radios.forEach((radio)=> {
                if(radio.value === el.defaultValue)
                  radio.checked = true;
              });
              this.clocktype = el.defaultValue;
              break;
          }
        }
      });
    });
    await this.saveSettings();
    await this.loadSavedSettings();
  }

  async loadSavedSettings(settingsContainer = ".ext-settings") {
    let s = await Util.getSetting("savedSettings");
    let savedSettings = typeof s === 'string' ? JSON.parse(s) : s;
    this.savedSettings = savedSettings;
    this.applySavedSettingsToInputs(savedSettings, settingsContainer);
    this.applySavedSettingsToElements(savedSettings, this.extSettings);
    this.applyClockSetting(this.clocktype);
  }

  applySavedSettingsToInputs(settings = this.savedSettings, settingsContainer = ".ext-settings") {
    let inputs = [].slice.call($(`${settingsContainer} input`),0);
    for (var key in settings) {
      if (settings.hasOwnProperty(key)) {
        let input = inputs.find((el) => {
          return el.dataset.identifier === key;
        });
        if(key === 'bgColor') {
          $('.bgColorInput').attr('value', settings[key]);
          $('.bgColorInput').focus().change();
        }
        else if(key === 'clockColor') {
          $('.clockColorInput').attr('value', settings[key]);
          $('.clockColorInput').focus().change();
        }

          if(input !== undefined){
          switch(input.type){
            case "checkbox":
            input.checked = settings[key];
            break;
            case "text":
            input.setAttribute("value",settings[key]);
            break;
            case "radio":
            let radios = [].slice.call($(`input[name=${input.name}]`),0);
            radios.forEach((el)=> {
              if(el.value === settings[key]) {
              el.checked = true; }
              else if(el.name === 'timeformat') {
                this.timeFormat = settings[key]; }
              else if (el.name === 'clocktype') {
                this.clocktype = settings[key]; }
            });
            break;
          }
        }
      }
    }
  }

  applySavedSettingsToElements(savedSettings = this.savedSettings, extSettings = this.extSettings) {
    for (var key in savedSettings) {
      if (savedSettings.hasOwnProperty(key)) {
          if(key === 'bgColor') {
              $('body').css('background', savedSettings[key]);
          }
          else if(key === 'clockColor') {
              $('#main_clock').css('color', savedSettings[key]);
          }
          let extSetting = extSettings.find((el) => {
          return el.identifier === key;
        });

        if(extSetting !== undefined) {
          let { elementSelector, handlerName, type, identifier } = extSetting;

          if(type === "toggle" || type === "radio"){

            if(savedSettings[key] === false && typeof this[handlerName] === "function"){
              $(elementSelector).hide();
            }
            else {
              $(elementSelector).show();
            }
          }
          else {
            this[handlerName](elementSelector);
          }
        }
      }
    }
  }

    //apply clock settings to Digital or Classic based on settings
    applyClockSetting(setting = "Digital"){
        if (setting == "Digital"){
            $('div#main_clock').show();
            $('div#wall_clock').hide();
        }
        else if (setting == "Classic"){
            $('div#main_clock').hide();
            $('div#wall_clock').show();
        }
    }

  // Attaches handlers to settings buttons
  addSettingButtonsHandlers() {
    $(document).on("click", ".saveBtn", async () => await this.saveSettings());
    $(document).on("click", ".resetBtn", async (e) => await this.resetSettings());
  }

  // Add appropriate handler for setting based on setting type
  addSettingHandler(setting) {
    let { type, identifier, elementSelector, handlerName } = setting;
    switch(type) {
      case "toggle" :
        $(document).on("change", `input[data-identifier=${identifier}]`, () => {
          if(handlerName) {
            if(typeof this[handlerName] === "function")
              this[handlerName](elementSelector);
          }
          else {
            this.handleHideElement(elementSelector);
          }
        });
        break;
        case "radio" :
            $(document).on("change", `input[data-identifier=${identifier}]`, (e) => {
                if(handlerName) {
                    if(typeof this[handlerName] === "function")
                        this[handlerName].call(e.target, elementSelector, this);
                }
            });
        break;
    }
  }

  // Handler that hides the selector element
  handleHideElement(elementSelector) {
    $(elementSelector).toggle();
  }

  // Returns the html for the provided setting type
  // i.e. checkbox for type: "toggle"
  getSettingHTML(setting) {
    let { name, type, elementSelector, identifier, classList, defaultValue } = setting;
    let html;
    let classesToAdd = classList.length > 0 ? classList : "";
    switch(type) {
      case "toggle" :
      let checkedAttr = defaultValue === "on" ? "checked" : null;
      html = `
        <fieldset class="${classesToAdd}">
          <span>${name}</span>
          <div class="switch">
            <input type="checkbox" name="toggle" data-identifier="${identifier}"
            data-controls="${elementSelector}" ${checkedAttr}>
            <label for="toggle"><i></i></label>
            <span></span>
          </div>
        </fieldset>`;
      break;

      case "text" :
      html = `
        <fieldset class="${classesToAdd}">
          <span>${name}:</span>
          <input data-identifier="${identifier}" type="text" class="${classesToAdd}" name="settings_text"/>
        </fieldset>
        `;
      break;
      case "radio" :
      let { options } = setting;

      let optionString = "";
      for (var key in options) {
        if (options.hasOwnProperty(key)) {
          let isChecked = defaultValue === key ? "checked" : null;
          let newOption = `<label for="${key}">${key}</label>
          <input name="${identifier}" data-identifier="${identifier}" type="radio" value="${options[key]}" ${isChecked}/>`;
          optionString = optionString.concat(newOption);
        }
      }
      html = `
        <fieldset class="${classesToAdd}">
          <span>${name}:</span>
          ${optionString}
        </fieldset>
        `;
      break;

    }
    return html;
  }
}

module.exports = new SettingsManager();
