const Util = require("./util.js");
const SettingsManager = require("./SettingsManager.js");


class ArcadeTab {

  constructor() {
    this.serviceEndpoint = "https://arcade-services.myarcadetab.com/api/get.php";
    this.gamesList = {};
    this.activeCookieCategory = '';
    this.cookieCatPresent = false;
    this.extSettings = SettingsManager.retrieveSettings();
  }

 async init(firstRun, genre, genreChange) {
      this.giveSelectedGenre(genre).then((resSelectedGenre) => {
      var selectedGenre = resSelectedGenre;
          SettingsManager.showGenres().then(() => {

      $('div.nav__menu li[cat="' + selectedGenre + '"]').addClass('active');
              $('ul.setCat li[cat="' + Util.getSetting("cat") + '"]').addClass('active');
              SettingsManager.retrieveGenres().then(async (res) => {
                  var current_genre_obj =  this.give_selected_obj(res,resSelectedGenre);
                  var current_genre_index = res.indexOf(current_genre_obj);
                  $(".moreGenre").val((((current_genre_index !== -1) && (current_genre_index<6))||(selectedGenre === 'all')) ? 'more' : selectedGenre);
        let games = this.retrieveGames((selectedGenre === 'all') ? '' : selectedGenre, '');
                  games.then(async (res) => {
                      this.gamesList = typeof res === "string" ? JSON.parse(res) : res;
                      Util.setSetting("gamesList", JSON.stringify(this.gamesList));
                         $(".moreGenre").show();
                          if(this.gamesList) {
                              let rand = Math.floor(Math.random() * this.gamesList.length);
                              let savedGame = Util.getSetting('saved_game', null);
                              if (savedGame === null) {
                                  this.displaySelectedGame(rand.toString());
                              }
                              else {
                                  this.displaySelectedGame(savedGame);
                              }
                           }
                               this.extSettings.then(async (settings) => {
                                   if(!genreChange) {
                                       SettingsManager.addSettingsToDOM(settings, ".ext-settings");
                                       await SettingsManager.loadSavedSettings();
                                   }
                                   this.addGames(res);

                               });
                  });
              });
          });
      });
  }
    giveSelectedGenre(genre) {
        return new Promise((resolve, reject) => {
            var selectedGenre = '';
            if (genre) {
                selectedGenre = genre;
                resolve(selectedGenre);

            } else if (Util.getSetting("cat")) {
                selectedGenre = Util.getSetting("cat");
                resolve(selectedGenre);
            } else {
                this.checkGenreCookies().then((res) => {
                    if (res && res.length > 0) {
                        selectedGenre = res;
                        Util.setSetting("cat", res);
                    } else {
                        selectedGenre = 'all';
                        Util.setSetting("cat", 'all');
                    }
                    resolve(selectedGenre);
                });
            }
        });
    }


    give_selected_obj(res,resSelectedGenre){
        for(var i=0; i<=res.length; i++) {
            if(res[i] && res[i].id && (res[i].id === resSelectedGenre)) {
                return  res[i];}}}

    getGamesHTML(games){
        const gamesHTML = ({title, description, logo,snap, url, id, releaseYear,publisher,emulator_code}) =>

            ` <div class="gameTile"><a class='game-banner-link' href='${url}' gameTitle= '${title}' target='_blank'>
              <img src="${logo}" class="gameLogo" data-identifier="${id}" data-emulatorcode="${emulator_code}"  alt=""
                onerror="this.style.display='none !important';"/>
           <p class="gameName">${title}</p>
                        <!--<a href="#" class="gameEntry" data-identifier="" data-emulatorcode="">-->
             <a href="#" class="gameManual gameEntry" data-identifier="${id}" data-emulatorcode="${emulator_code}" ><i class="fa fa-info-circle" aria-hidden="true"></i></a>
             <!--</a>-->
         <a class='game-banner-link' href='${url}' gameTitle= '${title}' style="position: absolute;" target='_blank'>  <i class="fa fa-play-circle playButton " aria-hidden="true"></i> </a>
        </a></div>`;

        return games.map(gamesHTML).join('');
    }


    retrieveGames(genreid,gameid) {
        let retGames = JSON.parse(Util.getSetting('genre'+genreid+'game'+gameid));
        if (retGames && !Util.checkIfUpdate(retGames && retGames[retGames.length-1].lastCall, 1440)) {
            return new Promise((resolve, reject) => {
                resolve(retGames);
                return retGames;
            });
        } else {
            let games = {
                url: this.serviceEndpoint,
                data: {
                    action: "games",
                    catid: 1,
                    limit: '',
                    genreid: genreid,
                    gameid: gameid
                },
                name: 'genre'
            };
            return Util.getPromise(games).then((res) => {

            if(res && res.length > 0) {
                res.push({'lastCall': Date.now()});
                    Util.setSetting('genre' + genreid + 'game' + gameid, JSON.stringify(res));
                    return new Promise((resolve, reject) => {
                        resolve(res);
                        return res;
                    });
                } else {
                if(retGames && retGames.length > 0) {
                        return new Promise((resolve, reject) => {
                            resolve(retGames);
                            return retGames;});
                    } else { res.push({'lastCall': Date.now()});
                        return new Promise((resolve, reject) => {
                            resolve(res);
                            return res; });
                    }
                }
            });
        }
    }

    give_selected_obj(res,resSelectedGenre){
        for(var i=0; i<=res.length; i++) {
            if(res[i] && res[i].id && (res[i].id === resSelectedGenre)) {
                return  res[i];}}}

    //Update topBar on load with GoogleNews by default
    async showFirstRunGame() {
        this.checkGameCookies().then((resGame) => {
        let preSelectedGame = [];
        if(resGame) {
                this.retrieveGames('', resGame).then((res) => {
            if (res.length > 0) {
                        res.forEach(function (v) {
                            let title = v.title;
                            let url = v.url;
                            if (url && title) preSelectedGame.push({
                                title: title,
                                description: v.description,
                                logo:  v.logo || "/assets/images/fallback.jpg",
                                snap: v.snap,
                                url: url,
                                id: v.id,
                                releaseYear: v.releaseYear,
                                publisher: v.publisher,
                                emulator_code: v.emulator_code
                            });
                            let rendered = SettingsManager.getFirstRunGameHTML(preSelectedGame);
                            $('.gameTileFirstRun').html(rendered);
                            $('.gameTileFirstRun').css('display','inline-block');

                        });
                    }
                });
            }
        });
    }

    addGames(parsedXML){
        let games = [];
        if(parsedXML) {
            parsedXML.forEach(function (v) {
                let title = v.title;
                let description = v.description;
                let snap = v.snap;
                let url = v.url;
                let id = v.id;
                let releaseYear = v.release_year;
                let publisher = v.publisher;
                let emulator_code = v.emulator_code;
                if (url && title) games.push({
                    title: title,
                    description: description,
                    logo: v.logo || '/assets/images/fallback.jpg',
                    snap: snap,
                    url: url,
                    id: id,
                    releaseYear: releaseYear,
                    publisher: publisher,
                    emulator_code: emulator_code
                });
            });
            games.sort(function (a, b) {
                return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
            });

            let rendered = this.getGamesHTML(games);
            $('.gamesContainer').html(rendered);
        }
    }

    getGamesHTML(games){
        const gamesHTML = ({title, description, logo,snap, url, id, releaseYear,publisher,emulator_code}) =>
            ` <div class="gameTile"><a class='game-banner-link' href='${url}' gameTitle= '${title}' target='_blank'>
              <img src="${logo}" class="gameLogo" data-identifier="${id}" data-emulatorcode="${emulator_code}"  alt=""
                onerror="this.style.display='none !important';"/>
           <p class="gameName">${title}</p>
             <a href="#" class="gameManual gameEntry" data-identifier="${id}" data-emulatorcode="${emulator_code}" ><i class="fa fa-info-circle" aria-hidden="true"></i></a>
         <a class='game-banner-link' href='${url}' gameTitle= '${title}' style="position: absolute;" target='_blank'>  <i class="fa fa-play-circle playButton " aria-hidden="true"></i> </a>
        </a>
               </div>`;
        return games.map(gamesHTML).join('');
    }



//check if any existing cookie present for Genre
    async checkGenreCookies() {
        let genreCookie =  Util.getSetting('genre');
        return new Promise((resolve, reject) => {
            if (genreCookie && genreCookie.length > 0) {
                SettingsManager.genreCookie = genreCookie;
            }
            else {
                SettingsManager.genreCookie = '';
            }
        resolve(SettingsManager.genreCookie);
     });
    }

    //check if any existing cookie present for game
    async checkGameCookies() {
        let gameCookie =  Util.getSetting('game');
        return new Promise((resolve, reject) => {
            if (gameCookie && gameCookie.length > 0) {
                SettingsManager.gameCookie = gameCookie;
            }
            else {
                SettingsManager.gameCookie = '';
            }
        resolve(SettingsManager.gameCookie);
        });
    }


    populateGameInfo(game) {

    let $gameDesc = $("#gameDesc");
    if(game && (typeof game.description !== "undefined" || typeof game.description !== null || game.description !== ""))
    $gameDesc.html(game.description);
    else {
      $gameDesc.html("Description not available.");
    }

  }

  displaySelectedGame(id) {
    let screenShot = $("#screenshot img");
    let gameLogo = $(".gameLogo img");
    let playURL = '';
    let selectedGame = this.gamesList.find( (game) => { return game.id === id; });

      if(selectedGame) {
    let logoURL = selectedGame.logo || "/assets/images/fallback.jpg";
     playURL = selectedGame.url;
    let snapURL = selectedGame.snap;
    this.populateGameInfo(selectedGame);

    screenShot.attr("src", snapURL);
    gameLogo.attr("src", logoURL);

    }
    let button = $("button.startbtn");
    $(button).off("click");
    $(button).on("click", (e) => {
      e.preventDefault();
      if(playURL  && playURL.length > 0) {
      window.open(playURL, "_blank"); }
    });

  }

}

const arcadeTab = new ArcadeTab();
module.exports = arcadeTab;
